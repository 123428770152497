/*Close button*/
.close-button,
.shepherd-cancel-icon {
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('../../images/shared/close_thick_50px.svg');
	filter: invert(100%);
	cursor: pointer;
}

.close-button.padding{
	background-image: url('../../images/shared/close_thick_padding_50px.svg');
}

.close-button:hover,
.shepherd-cancel-icon:hover {
	opacity: 0.8;
	cursor: pointer;
}