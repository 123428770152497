/* @tailwind base; */
@import "./preflight.css";
@tailwind components;
@tailwind utilities;

@layer components {
  .disable{
    @apply pointer-events-none opacity-50;
  }
  #tags span{
    @apply bg-white p-2 rounded cursor-pointer select-none;
  }
  #tags span.select{
    @apply bg-green;
  
  }
  .card.active{
    @apply !bg-green !cursor-default;
  }
}