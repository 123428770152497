.key{
	display: inline-block;
	font-weight: 700;
	border-radius: 3px;
	min-width: 1.5em;
	text-align: center;
	text-transform: uppercase;
	background-color: #cccccc;
	color: #000000;
}

.key.padding{
	padding: 3px;
}

.key.height-23{
	height: 23px;
}